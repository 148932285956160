@import "../../css/main";

.partners {
	width: 62rem;
	margin: 0 auto;

	@include respond(max, 992px) {
		width: 100%;
	}

	&__list {
		margin: 0 5.6875rem;

		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
		-ms-grid-columns: 1fr 1fr 1fr;
		row-gap: 6rem;

		@include respond(max, 992px) {
			row-gap: 2rem;
		}

		@include respond(max, 992px) {
			margin: 0;
			grid-template-columns: repeat(
				auto-fit,
				minmax(calc(100% / 3), calc(100% / 2))
			);
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
			width: calc(100% / 3);
			float: left;
			margin: 2rem 0;
		}
	}

	&__img {
		margin-bottom: 0.25rem;

		@include respond(max, 992px) {
			height: 6.25rem;
			flex: 0 0 6.25rem;
		}
	}

	&__name {
		font-size: 1.25rem;
		line-height: 1.875rem;
		font-weight: $ftw-rg;
	}
}
