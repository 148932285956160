@import "./main.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	outline: none;
	text-decoration: none;
	background-color: transparent;
	position: relative;
	z-index: 0;
	border: none;
	list-style: none;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;

	font-family: inherit;

	*::-webkit-scrollbar {
		display: none;
	}
}

html {
	font-family: "Noto Sans CJK TC", sans-serif;
	overflow: hidden;

	@include respond(max, 992px) {
		font-size: 87.5%;
	}
}

body {
	overflow: hidden;
}

#root {
	overflow: hidden;
}

.App {
	overflow-y: scroll;

	max-height: 100vh;
	max-width: 100vw;

	@supports (max-height: var(--vh)) {
		max-height: var(--vh);
		max-width: var(--vw);
	}

	display: flex;
	flex-direction: column;
	align-items: center;
}

section {
	padding-top: 6.25rem !important;
	margin-top: -6.25rem !important;

	@include respond(max, 992px) {
		padding-top: 3.25rem !important;
		margin-top: -3.25rem !important;
	}
}

img {
	vertical-align: top;
}

footer {
	width: 100%;
	line-height: 3rem;
	padding-left: 6.5rem;
	background-color: #797979;
	font-weight: $ftw-rg;
	font-size: 1rem;
	color: #ffffff;

	@include respond(max, 992px) {
		padding-left: 0;
		text-align: center;
	}
}

.aply-btn {
	position: fixed;
	right: 1.6875rem;
	bottom: 2.5rem;
	padding: 1rem 1.5rem;
	font-weight: $ftw-md;
	font-size: 1.5rem;
	line-height: 2.25rem;
	background-color: $color-primary;
	color: #ffffff;
	border-radius: 8px;
	cursor: pointer;
	z-index: 1000;

	@include respond(max, 992px) {
		font-size: 1.142857142857143rem;
		line-height: 1.714285714285714rem;
		padding: 0.714285714285714rem 2rem;
		right: 1.142857142857143rem;
		bottom: 1.428571428571429rem;
	}
}

.seperate {
	margin: 4.5rem 0;
}

.primary__heading {
	font-size: 2.5rem;
	line-height: 3.75rem;
	font-weight: $ftw-md;
	text-align: center;
	color: $color-primary;
	width: 100%;

	margin-bottom: 3rem;

	@include respond(max, 992px) {
		margin-bottom: 1.375rem;
	}
}

.pretend {
	display: none;

	@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
		display: block;
		width: 100%;
		height: 100px;
	}
}
