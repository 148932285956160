@import "../../css/main.scss";

.carousel {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	overflow: hidden;
	margin-top: 6.25rem;
	margin-bottom: 2.25rem;

	@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
		margin-top: 0;
	}

	z-index: 100;

	flex-shrink: 0;

	@include respond(max, 992px) {
		margin-top: 3.571428571428571rem;
		margin-bottom: 2rem;
	}

	&__container {
		width: fit-content;
		overflow: visible;
	}

	&__group {
		position: relative;
		margin: 0 auto;
		list-style: none;

		&--item {
			display: block;
			width: fit-content;
			height: fit-content;
			position: absolute;
			top: 0;
			left: 0;
			transform-style: preserve-3d;
			will-change: opacity, transform;
		}
	}

	&__img {
		@include respond(max, 992px) {
			width: calc(var(--vw) * 0.925);
		}
	}

	&__prevbtn,
	&__nextbtn {
		position: absolute;
		z-index: 1000;
		width: 2.25rem;
		height: 2.25rem;
		border-radius: 50%;
		background-color: #fff;
		cursor: pointer;

		display: flex;
		align-items: center;
		justify-content: center;

		top: 50%;

		@include respond(max, 992px) {
			height: 1.428571428571429rem;
			width: 1.428571428571429rem;
		}
	}

	&__prevbtn {
		left: 14%;
		transform: translate(-50%, -50%);
		left: 0;
	}

	&__nextbtn {
		right: 14%;
		transform: translate(50%, -50%);
		right: 0;
	}

	&__icon {
		height: 1.5rem;

		@include respond(max, 992px) {
			height: 1rem;
		}

		&--left {
			margin-right: 0.3125rem;
		}

		&--right {
			margin-left: 0.3125rem;
		}
	}

	&__cover {
		position: absolute;
		top: 0;
		background-color: rgba($color: #ffffff, $alpha: 0.8);

		&--right {
			right: 0;
			transform: translateX(100%);
		}

		&--left {
			left: 0;
			transform: translateX(-100%);
		}
	}
}

.dot {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	left: 0;

	bottom: 1rem;

	&__dot {
		width: 0.625rem;
		height: 0.625rem;
		border-radius: 50%;
		background-color: #003371;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 0.75rem;
		}

		&.active {
			background-color: #bfdaec;
		}
	}
}
