@import "../../css/main.scss";

.usecase {
	width: 62rem;
	margin: 0 auto;

	@include respond(max, 992px) {
		width: 100%;
	}

	&__section {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__box {
		display: flex;
		// align-items: center;
		@include respond(max, 992px) {
			flex-direction: column;
			margin: 0 1.5rem;
		}

		&:not(:last-child) {
			margin-bottom: 4.5rem;
		}
	}

	&__img {
		width: 20rem;
		height: 12.5rem;
		object-fit: cover;

		&--left {
			margin-right: 3rem;
		}

		&--right {
			margin-left: auto;
		}

		@include respond(max, 992px) {
			align-self: center;

			&--left {
				margin-right: 0;
			}

			&--right {
				margin-left: 0;
			}
		}
	}

	&__instruction {
		@include respond(max, 992px) {
			order: 1;
		}
	}

	&__heading {
		margin: 0.5rem 0;
		font-size: 2rem;
		line-height: 2rem;
		font-weight: $ftw-md;
		color: $color-primary;
		padding-left: 1.15625rem;
		border-left: 5px solid $color-primary;

		@include respond(max, 992px) {
			margin-top: 0.857142857142857rem;
		}
	}

	&__item {
		line-height: 2.25rem;
		font-size: 1.25rem;
		font-weight: $ftw-rg;
		color: #2e2e2e;
		display: flex;

		&::before {
			content: "-";
			margin-right: 1rem;
		}

		@include respond(max, 992px) {
			& div {
				display: inline-block;
			}
			&::before {
				content: "\2022";
				display: inline-block;
				color: #797979;
				// float: left;
			}
		}
	}
}
