@import "../../css/main";

.feature {
	width: 62rem;
	padding: 4.5rem 0;
	margin: 0 auto;

	@include respond(max, 992px) {
		width: 100%;
		padding: 0;
		margin-top: 2rem;
	}

	&__list {
		display: flex;
		flex-direction: column;
		width: 100%;
		position: relative;

		&-row {
			display: flex;
			width: 100%;
			align-items: center;
			flex-wrap: wrap;

			&:not(:last-child) {
				margin-bottom: 3rem;
			}

			@include respond(max, 992px) {
				flex-direction: column;

				&:not(:last-child) {
					margin-bottom: 0;
				}

				& > * {
					margin: 0 !important;
				}
			}

			&--1 {
				justify-content: space-between;
			}

			&--2 {
				justify-content: center;
			}
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;

		@include respond(max, 992px) {
			border: 1px solid #f5f5f5;
			border-radius: 4px;

			width: 100%;
		}

		&--1 {
			margin-left: 6.875rem;
		}
		&--3 {
			margin-right: 6.875rem;
		}
		&--4 {
			margin-right: 10.5rem;
		}
		&--5 {
			margin-left: 10.5rem;
		}
	}

	&__icon {
		height: 6.25rem;
		width: 6.25rem;
		display: flex;
		justify-content: center;
		align-items: center;

		& * {
			fill: #aaaaaa !important;
		}
	}

	&__name {
		margin-top: 1.25rem;
		line-height: 2.25rem;
		font-size: 1.5rem;
		color: #aaaaaa;
		font-weight: $ftw-md;
	}

	&__triangle {
		@include respond(max, 992px) {
			display: none;
		}

		position: absolute;
		z-index: 2;

		&::before {
			position: absolute;
			top: 0;
			left: 50%;
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent #1463a4 transparent;
			transform: translateX(-50%);
		}

		&::after {
			position: absolute;
			top: 2.5px;
			left: 50%;
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 20px 20px 20px;
			border-color: transparent transparent #f5f5f5 transparent;
			transform: translateX(-50%);
		}
	}

	&__content {
		width: 100%;
		background-color: #f5f5f5;
		flex: 0 0 100%;
		z-index: 1;
		margin-top: 10px;
		display: flex;
		justify-content: flex-start;
		opacity: 0;

		transition: height 0.3s, opacity 0.1s 0.1s;

		height: 0;
		border: none;

		@include respond(max, 992px) {
			display: none;
		}

		&.active {
			border-top: 2px solid #1463a4;
			border-bottom: 2px solid #1463a4;
			height: 7.75rem;
			opacity: 1;
		}

		&--text {
			flex: 0 0 calc(100% / 3);
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: $ftw-rg;
			font-size: 1.25rem;
			line-height: 1.875rem;
			color: $color-primary;
			text-align: center;
		}
	}

	&__item.active {
		& * {
			fill: #1463a4 !important;
			color: #1463a4;
		}
	}
}

.feature__content--mob {
	&__list {
		// display: none;
		display: flex;
		flex-direction: column;
		background-color: #f5f5f5;
		width: 100%;
		align-items: center;
		text-align: center;
		overflow: hidden;

		transition: all 1s;

		height: 0;

		&.active {
			padding: 1.5rem 0;
			height: 9.571428571428571rem;
		}
	}

	&__item {
		font-weight: $ftw-rg;
		font-size: 1rem;
		line-height: 1.428571428571429rem;

		&:not(:last-child) {
			margin-bottom: 1.142857142857143rem;
		}
	}
}
