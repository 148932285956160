@import "../../css/main.scss";

.navibar {
	width: 100%;
	height: $nav-height;
	padding-left: 6.5rem;
	padding-right: 6.625rem;

	display: flex;
	align-items: center;
	justify-content: space-between;

	box-shadow: 0px 3px 2px #00000014;

	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	z-index: 9999;
	overflow: hidden;

	@include respond(max, 992px) {
		display: block;
		transition: height 0.3s;
		height: $nav-height-mob;
		padding: 0 $nav-vertical-spacing-mob;

		&.show {
			height: calc(#{$nav-height-mob} + #{$list-height-mob});
		}
	}

	&__logo {
		width: 13.125rem;

		@include respond(max, 992px) {
			margin-top: $nav-vertical-spacing-mob;
			height: 1.857142857142857rem;
			width: 6.5rem;
		}
	}

	&__list {
		list-style: none;
		display: flex;
		align-items: center;

		@include respond(max, 992px) {
			flex-direction: column;
			background-color: #fff;
			position: absolute;
			top: $nav-height-mob;
			left: 0;

			width: 100%;
			height: $list-height-mob;
		}
	}

	&__item {
		&:not(:last-child) {
			margin-right: 2rem;
		}

		@include respond(max, 992px) {
			width: 100%;
			text-align: center;

			&:not(:last-child) {
				margin-right: 0;
				border-bottom: 1px solid #d1d1d1;
				padding: 0.75rem 1.5rem;
			}

			&--last {
				border-bottom: none !important;
			}
		}
	}

	&__link {
		color: $color-primary;
		font-size: 1.125rem;
		font-weight: $ftw-rg;
		line-height: 1.75rem;
		width: 100%;
		display: block;

		&:hover {
			color: $color-primary-dark;
		}

		@include respond(max, 992px) {
			color: #797979;

			&.active {
				color: $color-primary;
			}
		}
	}

	&__button {
		display: none;
		position: absolute;
		top: $nav-vertical-spacing-mob;
		right: 1.714285714285714rem;
		z-index: 20;

		@include respond(max, 992px) {
			display: block;
		}

		&--icon {
			width: 2rem;
			height: 2.166666666666667rem;
		}
	}
}

.apply-btn {
	font-size: 1.125rem;
	font-weight: $ftw-rg;
	line-height: 1.75rem;
	background-color: $color-primary;
	color: #fff;
	padding: 0.625rem 3.125rem;
	border-radius: 0.5rem;
	margin-left: -0.25rem;

	&:hover {
		background-color: $color-primary-dark;
	}

	@include respond(max, 992px) {
		display: none;
	}
}
