@import "../../css/main";

.aboutus {
	width: 62rem;
	margin: 0 auto;

	@include respond(max, 992px) {
		width: 100%;
	}
}

.au {
	position: relative;

	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;

		@include respond(max, 992px) {
			width: 100%;
			padding: 0 0.714285714285714rem;
		}

		&--area {
			position: absolute;
			width: calc((50% - 0.714285714285714rem));
			padding-left: 0.714285714285714rem;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: left 0.3s, right 0.3s;

			&::after {
				content: "";
				display: block;
				width: 14.75rem;
				height: 4.25rem;
				background-color: $color-primary;
				border-radius: 100px;
			}
		}
	}

	&__btn {
		color: #aaaaaa;
		font-size: 1.5rem;
		line-height: 2.25rem;
		font-weight: $ftw-rg;
		height: 4.25rem;
		width: 14.75rem;
		border-radius: 100px;
		cursor: pointer;

		@include respond(max, 992px) {
			width: 10rem;
			height: 2.571428571428571rem;
		}

		&.active {
			color: #ffffff;
		}

		&--container {
			width: 100%;
			// background-color: yellowgreen;
			height: 4.25rem;

			display: grid;
			grid-template-columns: repeat(2, 1fr);
			align-content: center;
			justify-items: center;
		}

		@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
			width: 50%;
		}
	}
}

.core-value {
	margin-top: 4.5rem;
	width: 62rem;

	@include respond(max, 992px) {
		width: 100%;
	}

	&__main {
		display: flex;
		justify-content: center;
		align-items: center;

		border-left: 4.25rem solid #bfdaec;
		border-right: 2.125rem solid #bfdaec;

		@include respond(max, 992px) {
			border: none;
			flex-direction: column;
		}

		&--intro {
			color: #2e2e2e;
			font-weight: $ftw-md;
			font-size: 1.75rem;
			line-height: 2.625rem;
			padding: 0 1.75rem;

			display: flex;
			flex-direction: column;

			& span {
				width: 7rem;
			}

			@include respond(max, 992px) {
				flex-direction: row;
				height: 2.857142857142857rem;
				background-color: #bfdaec;
				align-items: center;
				justify-content: center;
				width: 100%;

				font-size: 1.142857142857143rem;

				& span {
					width: fit-content;

					&:not(:last-child) {
						padding-right: 1rem;
					}
				}
			}
		}

		&--desc {
			border-left: 0.625rem solid $color-primary;
			font-weight: $ftw-rg;
			font-size: 1.25rem;
			line-height: 2.25rem;

			padding: 0 2.6875rem;

			@include respond(max, 992px) {
				border: none;
				margin-top: 0.571428571428571rem;
			}
		}
	}

	&__list {
		margin-top: 2.5rem;
		margin-bottom: 30.4375rem;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
		gap: 1rem;

		@include respond(max, 992px) {
			width: calc(100% - (1.714285714285714rem * 2) - 1rem);
			margin: 0 1.714285714285714rem;
			margin-top: 1.142857142857143rem;
			margin-bottom: 11.142857142857143rem;
			grid-template-columns: repeat(2, 50%);
		}
	}

	&__item {
		display: flex;
		align-items: center;
		border: 1px solid #1463a4;

		@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
			width: calc(100% / 3 - 10px);
			float: left;

			&:nth-child(2),
			&:nth-child(5) {
				margin-left: 13px;
				margin-right: 13px;
			}

			margin-top: 10px;
			margin-bottom: 10px;
		}
	}

	&__icon {
		width: 5.25rem;
		height: 6rem;
		overflow: hidden;

		@include respond(max, 992px) {
			width: 2.857142857142857rem;
			height: 3rem;
		}
	}

	&__text {
		margin-left: 1rem;
		font-weight: $ftw-md;
		font-size: 1.75rem;

		@include respond(max, 992px) {
			font-size: 0.857142857142857rem;
		}
	}
}

.teams {
	margin-top: 4.5rem;
	margin-bottom: 21rem;

	@include respond(max, 992px) {
		margin: 0 1.714285714285714rem;
		margin-top: 2rem;
		margin-bottom: 11.142857142857143rem;
	}

	&__item {
		display: grid;
		grid-template-columns: 14.75rem 1fr;
		column-gap: 6.1875rem;

		&:not(:last-child) {
			margin-bottom: 3.5rem;
			@include respond(max, 992px) {
				margin-bottom: 3.142857142857143rem;
			}
		}

		@include respond(max, 992px) {
			grid-template-columns: 1fr;
			column-gap: 0;
		}
	}

	&__icon {
		width: 100%;
		@include respond(max, 992px) {
			width: 7.142857142857143rem;
			height: 7.142857142857143rem;
			justify-self: center;
		}

		@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
			width: 14.75rem;
		}
	}

	&__desc {
		&--heading {
			font-weight: $ftw-md;
			font-size: 1.75rem;
			line-height: 2rem;
			margin: 0.5625rem 0;

			padding-left: 1rem;
			border-left: 5px solid #1463a4;

			@include respond(max, 992px) {
				font-size: 1rem;
				line-height: 1.428571428571429rem;
			}
		}

		&--content {
			font-weight: $ftw-rg;
			font-size: 1.25rem;
			line-height: 2.5rem;
			@include respond(max, 992px) {
				font-size: 1rem;
				line-height: 1.857142857142857rem;
			}
		}
		@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
			display: inline-block;
			width: calc(100% - 14.75rem);
		}
	}
}
