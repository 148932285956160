$color-primary: #1463a4;
$color-primary-dark: #11517c;

$nav-height: 6.25rem;
$nav-height-mob: 3.571428571428571rem;
$list-height-mob: 13.142857142857143rem;
$nav-vertical-spacing-mob: 0.857142857142857rem;

$ftw-rg: 400;
$ftw-md: 700;

@mixin respond($minmax, $size) {
	@if $minmax == min {
		@media only screen and (min-width: $size) {
			@content;
		}
	}

	@if $minmax == max {
		@media only screen and (max-width: $size) {
			@content;
		}
	}
}
